@import "../scss/utils.scss";

.accordion {
  .item {
    @extend .cursorPointer;

    .header {
      display: flex;
      align-items: center;
      padding: 6vw 4vw;
      position: relative;

      @include devicesMax(mobile) {
        flex-direction: column;
        padding: 10vw 4vw 25vw 4vw;
      }

      &::after {
        content: "";
        @include position(absolute, auto, 0, 0, 0);
        @include dims(100%, 1px);
        background: #628f9a;
        opacity: 0.2;
      }

      &::before {
        content: "";
        @include position(absolute, 0, 0, 0, 0);
        @include dims(100%);
        background: transparent
          linear-gradient(90deg, #ffffff00 0%, #aea2ff7e 100%) 0% 0% no-repeat;
        transition: 1.2s all ease;
        transform: translateX(100%);
        z-index: 10;
        @extend .hide;
      }

      &.active {
        &::before {
          transform: translateX(0%);
          @extend .show;
        }
      }

      @media only screen and (min-width: 1025px) {
        &:hover {
          &::before {
            transform: translateX(0%);
            visibility: visible;
            opacity: 1;
          }
        }
      }

      .iconW {
        @include dims(10vw, auto);
        margin-bottom: auto;
        z-index: 50;

        @include devicesMax(mobile) {
          @include dims(20vw, auto);
          margin-bottom: 5vw;
        }
      }

      .text {
        margin-left: 2vw;
        z-index: 50;

        .title {
          color: #3b3b3b;
          font-family: DisplayJD;
          font-size: 3vw;

          @include devicesMax(mobile) {
            font-size: 10vw;
            text-align: center;
          }

          &:nth-child(1) {
            font-weight: 500;
            line-height: 100%;
          }

          &:nth-child(2) {
            font-weight: 200;
            line-height: 100%;
            font-style: italic;

            @include devicesMax(mobile) {
              font-weight: 500;
              font-style: normal;
              line-height: 100%;
            }
          }
        }

        .tags {
          display: flex;
          margin-top: 3vw;

          @include devicesMax(mobile) {
            align-items: center;
            justify-content: center;
            margin-top: 5vw;
          }

          .tag {
            border: 1px solid #95918d;
            border-radius: 46px;
            color: #3b3b3b;
            font-family: DisplayJD;
            font-size: 1rem;
            font-weight: 500;
            margin: 0 0.5rem 0.5rem 0;
            padding: 0.5rem 0.5rem 0.4rem 0.5rem;

            @include devicesMax(mobile) {
              border: 2px solid #95918d;
              font-size: 0.75rem;
              font-style: italic;
              font-weight: 400;
            }
          }
        }
      }

      .btn {
        @include position(absolute, 50%, auto, auto, 90%);
        @include dims(4vw, 3vw);
        background: #f2f2f2;
        border-radius: 46px;
        transform: translateY(-50%);
        z-index: 50;

        @include devicesMax(mobile) {
          @include position(absolute, 85%, auto, auto, 50%);
          @include dims(12vw, 9vw);
          transform: translateX(-50%);
        }

        .iconW {
          @include position(absolute, 50%, auto, auto, 50%);
          @include dims(40%, auto);
          transform: translate(-50%, -50%);
          transition: 0.4s all ease;
          transform-origin: center;

          &.plus {
            opacity: 1;
          }

          &.minus {
            opacity: 0;
          }

          &.active {
            &.plus {
              opacity: 0;
            }

            &.minus {
              opacity: 1;
            }
          }
        }
      }
    }

    .oWrapper {
      max-height: 0;
      overflow: hidden;
      position: relative;
      transition: 1.5s all ease;

      &::after {
        content: "";
        @include position(absolute, auto 0, 0, 0);
        @include dims(100%, 1px);
        background: #628f9a;
        opacity: 0.2;
      }

      &.active {
        max-height: 200vh;
      }
    }

    .content {
      @include dims(100%, auto);
      position: relative;

      &.consulting {
        padding: 4vw 0;

        display: flex;
        align-items: flex-start;

        @include devicesMax(tablet) {
          padding: 6vw 0;
        }

        @include devicesMax(mobile) {
          flex-direction: column;
          padding-top: 0;
        }

        .imgW {
          @include dims(35vw, 22.5vw);
          border-radius: 8px;
          flex-shrink: 0;
          margin: 12vw 2vw 0 5vw;
          overflow: hidden;
          z-index: 1;

          @include devicesMax(tablet) {
            @include dims(40vw, 27vw);
            font-size: 2vw;
            margin: 0;
            margin-left: 4vw;
            margin-top: 20vw;
          }

          @include devicesMax(mobile) {
            @include dims(100vw, 60vw);
            border-radius: 0;
            margin: 0;
          }
        }

        .text {
          padding: 0 4vw 0 2vw;

          @include devicesMax(mobile) {
            padding: 0 2vw;
          }

          .title {
            color: #3b3b3b;
            font-family: DisplayJD;
            font-size: 1.5vw;
            font-weight: 400;
            line-height: 140%;
            width: 70%;

            @include devicesMax(tablet) {
              font-size: 2.25vw;
              width: 100%;
            }

            @include devicesMax(mobile) {
              font-size: 4vw;
              line-height: 160%;
              margin: 8vw 4vw 0 4vw;
              text-align: center;
              width: auto;
            }
          }

          .par {
            color: #3b3b3b;
            font-family: DisplayJD;
            font-size: 1.5vw;
            font-weight: 400;
            line-height: 140%;
            margin: 8vw 4vw 0 auto;
            width: 60%;

            @include devicesMax(tablet) {
              font-size: 2.1vw;
            }

            @include devicesMax(mobile) {
              font-size: 4vw;
              line-height: 160%;
              margin: 8vw 4vw 0 4vw;
              text-align: center;
              width: auto;
            }
          }
        }
      }

      &.brandArchitecture {
        padding: 4vw 0;

        @include devicesMax(tablet) {
          padding: 6vw 0;
        }

        .top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 5vw;
          padding: 0 5vw;

          @include devicesMax(mobile) {
            flex-direction: column;
            padding: 0 2vw;
          }

          .par1 {
            color: #3b3b3b;
            font-family: DisplayJD;
            font-size: 1.25vw;
            font-weight: 400;
            line-height: 140%;
            width: 35vw;

            @include devicesMax(tablet) {
              font-size: 2.25vw;
              width: 50vw;
            }

            @include devicesMax(mobile) {
              font-size: 4vw;
              margin: 8vw 4vw 0 4vw;
              text-align: center;
              width: auto;
            }

            span {
              display: block;
              margin-bottom: 3vw;
            }
          }

          .par2 {
            color: #3b3b3b;
            font-family: DisplayJD;
            font-size: 1.25vw;
            font-style: italic;
            font-weight: 500;
            line-height: 160%;
            margin-right: 10vw;
            width: 20vw;

            @include devicesMax(tablet) {
              font-size: 1.75vw;
              margin-right: 0;
              width: 30vw;
            }

            @include devicesMax(mobile) {
              font-size: 4vw;
              margin: 8vw 4vw 0 4vw;
              text-align: center;
              width: auto;
            }
          }
        }

        .bottom {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          @include devicesMax(mobile) {
            flex-direction: column;
          }

          .imgW {
            @include dims(35vw);
            border-radius: 8px;
            flex-shrink: 0;
            overflow: hidden;
            z-index: 1;

            @include devicesMax(tablet) {
              @include dims(40vw, 35vw);
            }

            @include devicesMax(mobile) {
              @include dims(100vw, 80vw);
              border-radius: 0;
            }
          }

          .listW {
            border-top: 1px solid #000;
            margin: 5vw;
            padding-top: 4vw;

            @include devicesMax(mobile) {
              border-top: none;
              margin: 0;
              padding: 8vw 10vw;
              width: 100%;
            }

            .title {
              color: #3b3b3b;
              font-family: DisplayJD;
              font-size: 1.5vw;
              font-style: italic;
              font-weight: 500;
              line-height: 160%;
              margin-bottom: 3vw;

              @include devicesMax(tablet) {
                font-size: 2vw;
              }

              @include devicesMax(mobile) {
                font-size: 5vw;
                margin-bottom: 5vw;
              }
            }

            .lists {
              display: flex;
              position: relative;

              // &::after {
              //   content: "";
              //   @include position(absolute, 0 auto 0 50%);
              //   @include dims(1px, 100%);
              //   background: #000;
              // }

              @include devicesMax(mobile) {
                flex-direction: column;
                margin-left: 4vw;
              }

              .list {
                &:last-child {
                  margin-left: 7vw;

                  @include devicesMax(tablet) {
                    margin-left: 3vw;
                  }

                  @include devicesMax(mobile) {
                    margin-left: 0;
                  }
                }

                .item {
                  display: flex;
                  align-items: center;
                  margin-bottom: 0.5vw;

                  @include devicesMax(mobile) {
                    margin-bottom: 1vw;
                  }

                  .text {
                    color: #3b3b3b;
                    font-family: DisplayJD;
                    font-size: 1.25vw;
                    font-weight: 400;
                    line-height: 160%;
                    margin-left: 1rem;

                    @include devicesMax(tablet) {
                      font-size: 1.5vw;
                    }

                    @include devicesMax(mobile) {
                      font-size: 4vw;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.brandCoop {
        display: flex;
        justify-content: space-between;
        padding: 4vw 0 10vw 0;

        @include devicesMax(tablet) {
          padding: 6vw 0 27vw 0;
        }

        @include devicesMax(mobile) {
          flex-direction: column;
          align-items: center;
          padding: 8vw 2vw 0 2vw;
        }

        .text {
          margin: 0 5vw;

          .par1 {
            color: #3b3b3b;
            font-family: DisplayJD;
            font-size: 1.25vw;
            font-weight: 400;
            line-height: 160%;
            margin-bottom: 3vw;
            width: 35vw;

            @include devicesMax(tablet) {
              font-size: 2vw;
              width: 50vw;
            }

            @include devicesMax(mobile) {
              margin-bottom: 5vw;
              font-size: 4vw;
              text-align: center;
              width: 100%;
            }
          }

          .par2 {
            color: #3b3b3b;
            font-family: DisplayJD;
            font-size: 1.25vw;
            font-weight: 400;
            line-height: 160%;
            width: 35vw;

            @include devicesMax(tablet) {
              font-size: 2vw;
              width: 50vw;
            }

            @include devicesMax(mobile) {
              font-size: 4vw;
              text-align: center;
              width: 100%;
            }
          }
        }

        .imgs {
          position: relative;
          margin: 0 5vw;

          @include devicesMax(tablet) {
            margin: 0 6vw 0 0;
          }

          @include devicesMax(mobile) {
            margin: 0;
          }

          .imgW {
            &:nth-child(1) {
              @include dims(32.5vw, 40vw);
              border-radius: 16px;
              overflow: hidden;
              position: relative;
              z-index: 1;

              @include devicesMax(tablet) {
                @include dims(32.5vw, 45vw);
              }

              @include devicesMax(mobile) {
                @include dims(100vw, 70vw);
                border-radius: 0;
                margin-top: 10vw;
              }
            }

            &:nth-child(2) {
              @include position(absolute, auto, 80%, -15%, auto);
              @include dims(40vw, 22.5vw);
              border-radius: 8px;
              overflow: hidden;
              z-index: 5;

              @include devicesMax(tablet) {
                @include position(absolute, auto, 80%, -45%, auto);
                @include dims(47vw, 26vw);
              }

              @include devicesMax(mobile) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
