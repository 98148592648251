@import "../scss/utils.scss";
@import "../scss/variables.scss";

.selectWrapper {
  @include dims(3vw);
  @extend .cursorPointer;
  background: #3b3b3b10;
  border-radius: 50%;
  margin: 0.5rem 0.75rem;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  @include devicesMax(tablet) {
    background: transparent;
    margin: 0;
    opacity: 0.7;
  }

  .lngText {
    @include position(absolute, 50%, auto, auto, 50%);
    transform: translate(-50%, -40%);
    color: #3b3b3b;
    display: block;
    font-family: DisplayJD;
    font-size: 1rem;
    font-weight: 500;
    line-height: 100%;

    @include devicesMax(mobile) {
      font-size: 0.75rem;
    }
  }
}
