@import "../scss/utils.scss";
@import "../scss/variables.scss";

.home {
  .doubleTitleW {
    position: relative;
    white-space: nowrap;

    @include devicesMax(mobile) {
      text-align: center;
    }

    br {
      display: none;

      @include devicesMax(mobile) {
        display: block;
      }
    }

    .title {
      font-family: Kraton;
      font-weight: 400;
      line-height: 100%;

      &:nth-child(1) {
        color: #3b3b3b;
        font-size: 110px;

        @include devicesMax(tablet) {
          font-size: 10vw;
        }

        @include devicesMax(mobile) {
          font-size: 15vw;
        }
      }

      &:nth-child(2) {
        @include position(absolute, 0, auto, auto, 4%);
        color: #495660;
        font-size: 192px;
        opacity: 0.05;

        @include devicesMax(tablet) {
          font-size: 16vw;
        }

        @include devicesMax(mobile) {
          @include position(absolute, 0%, auto, auto, 50%);
          font-size: 21vw;
          transform: translateX(-50%);
        }
      }
    }
  }

  .hero {
    @include dims(100%, 90vh);
    padding: 10vw 8vw 0 3vw;

    @include devicesMax(tablet) {
      @include dims(100%, auto);
      flex-direction: column;
      align-items: center;
      padding: 10vw 0 10vw 0;
    }
  }

  .whatWeDo {
    padding: 15vw 8vw 7.5vw 3vw;

    @include devicesMax(tablet) {
      padding: 15vw 8vw 10vw 8vw;
    }

    @include devicesMax(mobile) {
      padding: 15vw 4vw 10vw 4vw;
    }

    .textW {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      margin-top: 10vw;

      @include devicesMax(tablet) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 15vw;
      }

      .par {
        @include dims(32vw, auto);
        color: #3b3b3b;
        font-family: DisplayJD;
        font-size: 20px;
        font-weight: 400;
        line-height: 180%;

        @include devicesMax(tablet) {
          @include dims(70vw, auto);
          text-align: center;
        }

        @include devicesMax(mobile) {
          @include dims(80vw, auto);
          font-size: 1rem;
        }

        &:nth-child(1) {
          margin-left: 6vw;

          @include devicesMax(tablet) {
            margin-left: 0;
          }
        }

        &:nth-child(2) {
          margin-top: 10vw;

          @include devicesMax(tablet) {
            margin-top: 5vw;
          }

          @include devicesMax(mobile) {
            margin-top: 100vw;
          }
        }

        strong {
          font-weight: 500;
          font-style: italic;
        }
      }
    }
  }

  .contactUs {
    @include dims(100%, 120vh);
    margin: 5vw 0 0 0;
    position: relative;

    @include devicesMax(tablet) {
      @include dims(100%, 70vw);
    }

    @include devicesMax(mobile) {
      @include dims(100%, auto);
    }

    .imgW {
      overflow: hidden;

      &.keycoPoster {
        @include position(absolute, 0, 0, auto, auto);
        @include dims(60vw, 40vw);

        @include devicesMax(tablet) {
          @include dims(65vw, 45vw);
        }

        @include devicesMax(mobile) {
          @include position(absolute, -650px, auto, auto, auto);
          @include dims(100%, 80vw);
        }
      }

      &.conversation {
        @include position(absolute, 30%, auto, auto, 10%);
        @include dims(27.5vw, 40vw);
        border-radius: 80px 8px 80px 8px;

        @include devicesMax(tablet) {
          @include position(absolute, 20vw, auto, auto, 11vw);
          @include dims(30vw, 45vw);
        }

        @include devicesMax(mobile) {
          display: none;
        }
      }
    }

    .btnW {
      @include position(absolute, -12%, auto, auto, 22%);
      display: block;
      z-index: 10;

      @include devicesMax(tablet) {
        @include position(absolute, -12vw, auto, auto, 18vw);
      }

      @include devicesMax(mobile) {
        @include position(relative, auto, auto, auto, auto);
      }

      .magneticW {
        @include dims(12.5vw);

        @include devicesMax(tablet) {
          @include dims(20vw);
        }

        @include devicesMax(mobile) {
          @include dims(50vw);
          margin: 10vw auto;
        }
      }
    }
  }

  .marqueeW {
    @include dims(100%, 15vw);
    overflow: hidden;
    position: relative;

    display: flex;
    align-items: center;

    &::after,
    &::before {
      content: "";
      @include position(absolute, 0, 0, auto, 0);
      @include dims(100%, 1px);
      background: #628f9a20;
    }

    &::before {
      @include position(absolute, auto, 0, 0, 0);
    }

    .textW {
      display: flex;
      align-items: center;

      .text {
        color: #3b3b3b;
        font-family: Kraton;
        font-size: 5.5vw;
        font-weight: 400;
        line-height: 100%;

        @include devicesMax(mobile) {
          font-size: 7.5vw;
        }
      }

      .iconW {
        @include dims(1.5rem);
        margin: 0 3vw;

        @include devicesMax(mobile) {
          @include dims(0.75rem);
        }
      }
    }
  }

  .projects {
    @include dims(100%, auto);
    padding-bottom: 20vw;

    .projectsTitle {
      margin: 10vw 8vw 10vw 4vw;

      @include devicesMax(tablet) {
        margin: 10vw 8vw 15vw 8vw;
      }

      @include devicesMax(mobile) {
        margin: 10vw 8vw 0 8vw;
      }
    }
  }
}
