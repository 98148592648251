@import "../scss/utils.scss";

.blogDetail {
  margin-bottom: 10vw;

  .content {
    padding: 10vw 15vw 2vw 15vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include devicesMax(tablet) {
      padding: 10vw 7.5vw 2vw 7.5vw;
    }

    @include devicesMax(mobile) {
      padding: 10vw 5vw 2vw 5vw;
    }

    .goBack {
      @include dims(100%, auto);
      border-bottom: 1px solid #3b3b3b;
      color: #3b3b3b;
      font-family: DisplayJD;
      font-size: 1.25vw;
      font-weight: 500;
      padding: 0.5rem 0;

      display: flex;
      align-items: center;

      @include devicesMax(tablet) {
        font-size: 2.5vw;
      }

      @include devicesMax(mobile) {
        font-size: 5vw;
      }

      .iconW {
        display: inline-block;
        padding-right: 0.5rem;
      }
    }

    .cover {
      margin: 3vw 0;

      @include devicesMax(mobile) {
        margin: 10vw 0;
      }
    }

    .contentHtml {
      margin: 3vw 0 10vw 0;

      @include devicesMax(mobile) {
        @include dims(100%, auto);
        margin: 3vw 5vw 10vw 5vw;
      }

      img {
        @include dims(90%, auto);
        display: block;
        object-fit: cover;
        margin: 5vw auto;

        @include devicesMax(mobile) {
          @include dims(95%, auto);
        }
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: #3b3b3b;
        font-family: DisplayJD;
        font-weight: 500;
        line-height: 100%;
        margin-bottom: 1.5vw;

        @include devicesMax(tablet) {
          line-height: 120%;
        }
      }

      h1 {
        font-size: 4vw;
        margin-bottom: 3vw;

        @include devicesMax(tablet) {
          font-size: 5vw;
        }

        @include devicesMax(mobile) {
          font-size: 8vw;
          margin-bottom: 5vw;
        }
      }

      h2 {
        font-size: 3vw;
      }

      h3,
      h4,
      h5,
      h6 {
        font-size: 2.5vw;
      }

      p {
        color: #3b3b3b;
        font-family: DisplayJD;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 200%;
        margin-bottom: 2vw;

        @include devicesMax(tablet) {
          font-size: 2.5vw;
        }

        @include devicesMax(mobile) {
          font-size: 3.75vw;
        }
      }
    }
  }

  .other {
    margin-left: 15vw;

    @include devicesMax(tablet) {
      margin-left: 10vw;
    }

    @include devicesMax(mobile) {
      margin-left: 0;
    }

    .title {
      @include dims(100%, auto);
      border-bottom: 1px solid #3b3b3b;
      color: #3b3b3b;
      font-family: DisplayJD;
      font-size: 1.5vw;
      font-weight: 500;
      margin-bottom: 3vw;
      padding: 0.5rem 0;

      display: flex;
      align-items: center;

      @include devicesMax(tablet) {
        font-size: 2.5vw;
      }

      @include devicesMax(mobile) {
        font-size: 5vw;
        margin-left: 5vw;
        margin-bottom: 7.5vw;
      }
    }

    .sliderW {
      display: block;
      @include dims(100%, auto);
    }
  }
}
