$sidebar-width: 10vw;
$sidebar-width-tablet: 10vw;
$sidebar-width-mobile: 15vw;

$mobile: 640px;
$tabletMini: 768px;
$tablet: 1024px;
$tabletPlusOne: 1025px;
$desktop: 1440px;
$widescreen: 1920px;
