@import "../scss/utils.scss";
@import "../scss/variables.scss";

$delay: 1s;
$transition: 0.4s all ease;

.menuW {
  @include position(fixed, 0, 0, 0, auto);
  @include dims(calc(100% - $sidebar-width), 100vh);
  background: linear-gradient(-135deg, #e4edee 20%, #f3e7e5 70%);
  pointer-events: none;
  transition: 0.8s all ease-in-out;
  transition-delay: 0.2s;
  z-index: 999999;
  @extend .hide;

  @include devicesMax(tablet) {
    @include dims(100vw, calc(100% - $sidebar-width-tablet));
    @include position(fixed, auto, 0, 0, 0);
  }

  @include devicesMax(mobile) {
    @include dims(100vw, calc(100% - $sidebar-width-mobile));
  }

  .nav {
    @include position(absolute, 50%, auto, auto, calc(100vw / 2));
    margin-right: $sidebar-width;
    pointer-events: auto;
    transform: translate(-120%, -50%);
    transition-delay: $delay;
    @extend .hide;

    @include devicesMax(tablet) {
      transform: translate(-50%, -50%);
      margin-right: 0;
    }

    &:before {
      content: "";
      @include position(absolute, 50%, auto, auto, 50%);
      @include dims(37vw, 75vh);
      background: transparent
        linear-gradient(180deg, #ffffff 0%, #f5eff1c7 43%, #f1e7eb00 100%) 0% 0%
        no-repeat padding-box;
      border-top-left-radius: 49%;
      border-top-right-radius: 49%;
      transform: translate(-50%, -40%) scaleX(1.3);
      transform-origin: center;
      transition: $transition;
      transition-delay: $delay;
      will-change: transform;
      z-index: -1;
      @extend .hide;

      @include devicesMax(tablet) {
        @include dims(80vw, 65vh);
      }

      @include devicesMax(mobile) {
        @include dims(100vw, 60vh);
      }
    }

    .links {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .linkW {
        position: relative;
        transform-origin: center;
        transition: $transition;
        transition-delay: $delay;
        will-change: transform;
        @extend .hide;

        &:hover {
          .circle {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
          }
        }

        &:nth-child(1) {
          transform: translateY(30%);
        }

        &:nth-child(2) {
          transform: translateY(20%);
          .circle {
            background: #f5eab8;
          }
        }

        &:nth-child(3) {
          transform: translateY(10%);
          .circle {
            background: #b4cded;
          }
        }

        .circle {
          @include position(absolute, 50%, auto, auto, 50%);
          @include dims(7vw);
          background: #d0b7dc;
          border-radius: 50%;
          opacity: 0;
          transform-origin: center;
          transform: translate(-50%, -50%) scale(0);
          transition: 0.3s all ease;
          will-change: transform;
          z-index: -1;

          @include devicesMax(tablet) {
            display: none;
          }
        }

        .indexW {
          @include position(absolute, 0, auto, auto, 0);
          @include dims(18px);
          transform: translate(-120%, 20%);
          border: 1px solid #3b3b3b;
          border-radius: 50%;

          display: flex;
          align-items: center;
          justify-content: center;

          @include devicesMax(mobile) {
            @include dims(14px);
          }

          .index {
            @include position(absolute, 50%, auto, auto, 50%);
            transform: translate(-50%, -45%);

            font-family: DisplayJD;
            font-size: 70%;
            line-height: 100%;

            @include devicesMax(mobile) {
              font-size: 50%;
            }
          }
        }

        .link {
          color: #3b3b3b;
          display: inline-block;
          font-family: DisplayJD;
          font-size: 4.5vw;
          line-height: 140%;
          font-weight: 500;

          @include devicesMax(tablet) {
            font-size: 8vw;
            line-height: 130%;
          }

          @include devicesMax(mobile) {
            font-size: 12vw;
            line-height: 140%;
          }
        }
      }
    }
  }

  &.open {
    pointer-events: auto;
    @extend .show;

    .nav {
      transition-delay: $delay;
      @extend .show;

      &:before {
        transform: translate(-50%, -50%) scaleX(1) scaleY(1);
        transition-delay: $delay;
        @extend .show;
      }

      .links {
        .linkW {
          transform: translateY(0);
          @extend .show;
        }
      }
    }
  }

  .social {
    @include position(absolute, auto, 0, 0, auto);
    display: flex;
    flex-direction: column;
    margin: 2vw;

    @include devicesMax(tablet) {
      @include position(absolute, auto, auto, 0, 50%);
      align-items: center;
      margin-bottom: 5vw;
      transform: translateX(-50%);
    }

    .link {
      color: #3b3b3b;
      font-family: DisplayJD;
      font-size: 1rem;
      font-weight: 400;
      line-height: 160%;

      @include devicesMax(tablet) {
        font-size: 1.25rem;
      }

      @include devicesMax(mobile) {
        font-size: 1rem;
      }
    }
  }
}
