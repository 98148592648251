@import "../scss/utils.scss";

.buttonCircle {
  @include dims(100%);
  background: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  color: #c6d1d5;
  position: relative;

  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  // filter: contrast(140%);
  // -webkit-filter: contrast(140%);

  @extend .cursorPointer;

  &::after {
    content: "";
    @include position(absolute, 50%, 0, 0, 50%);
    @include dims(95%);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background: transparent;
    border: 1px solid #c6d1d5;
  }

  .btnInner {
    @extend .abscenter;

    &.withText {
      display: flex;
      align-items: flex-start;
    }

    .text {
      color: #3b3b3b;
      font-family: DisplayJD;
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 100%;
      margin-left: 0.5rem;

      @include devicesMax(mobile) {
        font-size: 1.25rem;
      }
    }
  }
}
