@import "../scss/utils.scss";

.img {
  @include dims(100%);
  display: block;
  @extend .hide;

  &.visible {
    @extend .show;
  }
}
