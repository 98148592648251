@import "../scss/utils.scss";

.card {
  @include dims(35vw, 18vw);
  margin-right: 2vw;
  padding: 4vw 0;
  position: relative;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  @include devicesMax(tablet) {
    @include dims(70vw, 35vw);
  }

  @include devicesMax(mobile) {
    @include dims(70vw);
    align-items: center;
    flex-direction: column;
    margin: 15vw 15vw;
    padding: 0;
  }

  &::after {
    content: "";
    @include position(absolute, 0, 0, 0, 0);
    @include dims(100%);
    background: #959595;
    border-radius: 25px;
    opacity: 0.06;

    @include devicesMax(mobile) {
      border: 1px solid #dddddd;
      border-radius: 10px;
    }
  }

  .iconW {
    @include dims(25%, 70%);
    align-self: center;
    flex-shrink: 0;
    margin: 0 3vw;

    @include devicesMax(tablet) {
      @include dims(25%, 40%);
      margin: 0 7vw;
    }

    @include devicesMax(mobile) {
      @include dims(60%, 25%);
      margin: 7vw 0;
    }
  }

  .seperator {
    @include dims(1px, 100%);
    background: #f2f2f2;

    @include devicesMax(mobile) {
      @include dims(70%, 1px);
      background: #3b3b3b;
      opacity: 0.2;
    }
  }

  .text {
    flex: 1;
    color: #3b3b3b;
    margin: 2vw 1vw 0 2vw;

    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;

    @include devicesMax(tablet) {
      margin: 8vw 1vw 0 4vw;
    }

    @include devicesMax(mobile) {
      align-items: center;
      justify-content: center;
      margin: 5vw 0;
    }

    .title {
      font-family: DisplayJD;
      font-size: 1.25vw;
      font-weight: 600;
      font-style: italic;
      margin-bottom: 0.75em;
      line-height: 100%;
      width: 70%;

      @include devicesMax(tablet) {
        font-size: 2.5vw;
      }

      @include devicesMax(mobile) {
        font-size: 5vw;
        margin-bottom: 0.5em;
        text-align: center;
        width: 90%;
      }
    }

    .subtitle {
      font-family: DisplayJD;
      font-size: 1vw;
      font-weight: 400;
      line-height: 140%;
      width: 80%;

      @include devicesMax(tablet) {
        font-size: 2vw;
      }

      @include devicesMax(mobile) {
        font-size: 4vw;
        text-align: center;
      }
    }
  }
}
