@import "../scss/utils.scss";

.projects {
  padding: 8vw 4vw;

  @include devicesMax(mobile) {
    padding: 12vw 4vw;
  }

  .doubleTitleW {
    position: relative;
    white-space: nowrap;

    @include devicesMax(mobile) {
      text-align: center;
    }

    .title {
      font-family: Kraton;
      font-weight: 400;
      line-height: 100%;

      &:nth-child(1) {
        color: #3b3b3b;
        font-size: 110px;

        @include devicesMax(tablet) {
          font-size: 10vw;
        }

        @include devicesMax(mobile) {
          font-size: 15vw;
        }
      }

      &:nth-child(2) {
        @include position(absolute, 0, auto, auto, 4%);
        color: #495660;
        font-size: 192px;
        opacity: 0.05;

        @include devicesMax(tablet) {
          font-size: 16vw;
        }

        @include devicesMax(mobile) {
          @include position(absolute, 0%, auto, auto, 50%);
          font-size: 21vw;
          transform: translateX(-50%);
        }
      }
    }
  }

  .projectsList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(auto-fill, 1fr);
    grid-gap: 3vw;
    margin: 0 4.5vw;
    padding-top: 10vw;

    @include devicesMax(tablet) {
      grid-template-columns: repeat(1, 1fr);
      margin: 5vw 8vw;
    }

    @include devicesMax(mobile) {
      margin: 2vw 8vw;
      justify-content: center;
    }
  }
}

.projectCard {
  @include dims(auto, 18vw);
  padding: 4vw 0;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  @include devicesMax(tablet) {
    @include dims(auto, 35vw);
  }

  @include devicesMax(mobile) {
    @include dims(80vw);
    flex-direction: column;
    margin: 5vw 2vw;
    padding: 0 4vw;
  }

  &::after {
    content: "";
    @include position(absolute, 0, 0, 0, 0);
    @include dims(100%);
    background: #959595;
    border-radius: 25px;
    opacity: 0.06;

    @include devicesMax(mobile) {
      border: 1px solid #dddddd;
      border-radius: 10px;
    }
  }

  .iconW {
    @include dims(25%, 70%);
    flex-shrink: 0;
    margin: 0 3vw;

    @include devicesMax(tablet) {
      @include dims(auto, 35%);
      max-width: 35%;
      flex: 1;
    }

    @include devicesMax(mobile) {
      @include dims(60%, 30%);
      max-width: 100%;
      margin: 10vw 0 5vw 0;
    }
  }

  .seperator {
    @include dims(1px, 100%);
    background: #f2f2f2;

    @include devicesMax(mobile) {
      @include dims(70%, 1px);
      background: #3b3b3b;
      opacity: 0.2;
    }
  }

  .text {
    color: #3b3b3b;
    margin: 2.5vw 1vw 0 2vw;
    align-self: flex-start;

    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;

    @include devicesMax(tablet) {
      margin: 7vw 1vw 0 2vw;
    }

    @include devicesMax(mobile) {
      align-items: center;
      justify-content: center;
      margin: 5vw 0;
    }

    .title {
      font-family: DisplayJD;
      font-size: 1.25vw;
      font-weight: 600;
      font-style: italic;
      margin-bottom: 0.75em;
      line-height: 100%;
      width: 70%;

      @include devicesMax(tablet) {
        font-size: 3vw;
      }

      @include devicesMax(mobile) {
        font-size: 5.5vw;
        margin-bottom: 0.5em;
        text-align: center;
      }
    }

    .subtitle {
      font-family: DisplayJD;
      font-size: 1vw;
      font-weight: 400;
      line-height: 140%;
      width: 80%;

      @include devicesMax(tablet) {
        font-size: 2.25vw;
      }

      @include devicesMax(mobile) {
        font-size: 4vw;
        text-align: center;
      }
    }
  }
}
