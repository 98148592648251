@import "../scss/utils.scss";
@import "../scss/variables.scss";

.app {
  width: 100vw;
  height: 100vh;

  &:after {
    content: "";
    @include position(fixed, 0, 0, 0, 0);
    @include dims(100vw, 100%);
    background: linear-gradient(-135deg, #e4edee 20%, #f3e7e5 70%);
    z-index: -1;
  }

  .content {
    margin-left: $sidebar-width;
    position: relative;

    @include devicesMax(tablet) {
      margin-left: 0;
      padding-top: $sidebar-width-tablet;
    }

    @include devicesMax(mobile) {
      padding-top: $sidebar-width-mobile;
    }

    .languageW {
      @include position(absolute, 1vw, 1.5vw, auto, auto);

      @include devicesMax(tablet) {
        display: none;
      }
    }
  }
}
