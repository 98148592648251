@import "../scss/utils.scss";
@import "../scss/variables.scss";

.contact {
  @include dims(100%, 100vh);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include devicesMax(desktop) {
    @include dims(100%, auto);
  }

  .top {
    display: flex;
    justify-content: space-between;
    flex: 1;

    @include devicesMax(tablet) {
      flex-direction: column;
    }
  }

  .formW {
    @include dims(40vw, auto);
    margin: 5vw 4vw 0 4vw;
    position: relative;

    @include devicesMax(tablet) {
      @include dims(75%, auto);
      margin: 8vw 5vw 5vw 5vw;
      padding-bottom: 7.5vw;
    }

    @include devicesMax(mobile) {
      @include dims(100%, auto);
      padding: 15vw 5vw 15vw 5vw;
      margin: 0;
    }

    .error {
      @include position(absolute, auto, auto, 0, auto);
      color: #3b3b3b;
      font-family: DisplayJD;
      font-size: 1rem;
      font-weight: 400;
      line-height: 160%;
      margin: 2vw 0;

      @include devicesMax(tablet) {
        font-size: 14px;
      }

      @include devicesMax(mobile) {
        @include position(absolute, auto, auto, 0, 50%);
        transform: translateX(-50%);
        font-size: 11px;
        margin: 4vw 0;
        text-align: center;
      }
    }

    .imgW {
      &.mobileOnly {
        display: none;

        @include devicesMax(mobile) {
          @include dims(100vw, 70vw);
          display: block;
          margin: 10vw 0;
          transform: translate(-5vw);
        }
      }
    }

    .title {
      color: #3b3b3b;
      font-family: DisplayJD;
      font-size: 3vw;
      font-weight: 500;
      margin-bottom: 5vw;

      @include devicesMax(tablet) {
        font-size: 5vw;
      }

      @include devicesMax(mobile) {
        font-size: 10vw;
        text-align: center;
      }
    }

    .form {
      .top {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-gap: 3vw 2vw;
        width: 95%;

        @include devicesMax(tablet) {
          grid-gap: 5vw 10vw;
        }

        @include devicesMax(mobile) {
          grid-template-columns: repeat(1, 1fr);
          grid-template-rows: repeat(1, 1fr);
          grid-gap: 12vw 0;
          width: 100%;
        }
      }

      .bottom {
        margin-top: 3vw;

        @include devicesMax(tablet) {
          margin-top: 5vw;
        }
      }

      .error {
        @include position(absolute, 100%, auto, auto, auto);
        color: #3b3b3b;
        font-family: DisplayJD;
        font-size: 1rem;
        font-weight: 400;
        line-height: 160%;
        margin: 2vw 0;
      }

      .inputW {
        $fontSize: 1.35rem;
        @include dims(100%, 2.5vw);
        border-bottom: 1px solid #3b3b3b;
        position: relative;

        @include devicesMax(tablet) {
          @include dims(100%, 4vw);
        }

        @include devicesMax(mobile) {
          @include dims(100%, 9vw);
        }

        .label {
          @include position(absolute, 0, 0, 0, 0);
          @extend .flexstartH;
          color: #3b3b3b;
          font-family: DisplayJD;
          font-size: $fontSize;
          font-weight: 500;
          line-height: 120%;
          opacity: 0.3;
          transition: 0.4s all ease;

          &.hidden {
            transition: 0s;
            @extend .hide;
          }
        }

        .input {
          @include position(absolute, 0, 0, 0, 0);
          @extend .flexstartH;
          color: #3b3b3b;
          font-family: DisplayJD;
          font-size: $fontSize;
          font-weight: 400;
          line-height: 120%;
          margin: 0;
          padding: 0;
          background: none;
          border: none;
          outline: none;
        }

        .iconW {
          @include position(absolute, 50%, 0, auto, auto);
          @include dims(2vw, auto);
          transform: translateY(-50%);
        }
      }

      .left {
        display: flex;

        @include devicesMax(mobile) {
          flex-direction: column;
          align-items: center;
        }

        .inputGroup {
          display: flex;
          flex-direction: column;

          .textareaW {
            border-bottom: 1px solid #3b3b3b;
            display: flex;
            flex-direction: column;
            margin-bottom: 1.5vw;
            align-items: flex-start;
            justify-content: flex-start;

            @include devicesMax(tablet) {
              margin-bottom: 3vw;
            }

            @include devicesMax(mobile) {
              margin-bottom: 6vw;
              margin-top: 5vw;
            }

            .label {
              @include dims(100%, auto);
              @extend .flexstartH;
              color: #3b3b3b;
              font-family: DisplayJD;
              font-size: 1.5rem;
              font-weight: 500;
              line-height: 120%;
              margin-bottom: 0.5vw;
            }

            .textarea {
              @include dims(100%, 5vw);
              color: #3b3b3b;
              font-family: DisplayJD;
              font-size: 1.25rem;
              font-weight: 400;
              line-height: 120%;
              margin: 0;
              padding: 0;
              background: none;
              border: none;
              outline: none;
              resize: none;

              @include devicesMax(tablet) {
                @include dims(100%, 10vw);
              }

              @include devicesMax(mobile) {
                @include dims(100%, 30vw);
              }
            }
          }

          .confirmation {
            @extend .cursorPointer;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            .checkbox {
              $boxDims: 20px;
              @include dims($boxDims);
              background: #f2f2f2;
              border: 1px solid #3b3b3b;
              border-radius: 4px;
              flex-shrink: 0;
              position: relative;

              .inner {
                @extend .abscenter;
                @include dims(calc($boxDims - 6px));
                background: #3b3b3b;
                border-radius: 2px;
                transition: 0.3s all ease;
                @extend .hide;

                &.enabled {
                  @extend .show;
                }
              }
            }

            .text {
              margin-left: 0.5rem;
              width: 90%;

              @include devicesMax(tablet) {
                margin-left: 1rem;
                width: 70%;
              }

              @include devicesMax(mobile) {
                margin-left: 1rem;
                width: 100%;
              }

              .small,
              .link {
                color: #3b3b3b;
                display: inline;
                font-family: DisplayJD;
                font-size: 0.9vw;
                font-weight: 400;
                line-height: 180%;

                @include devicesMax(tablet) {
                  font-size: 1.5vw;
                }

                @include devicesMax(mobile) {
                  font-size: 3.25vw;
                }
              }

              .link {
                display: inline-block;
                text-decoration: underline;
              }
            }
          }
        }
      }

      .magneticW {
        pointer-events: none;

        &.enabled {
          pointer-events: auto;
        }

        .btnW {
          @include dims(12.5vw);
          flex-shrink: 0;
          margin-top: auto;
          margin-left: 2vw;
          margin-right: -3.5vw;
          opacity: 0.3;
          transition: 0.3s all ease;

          &.enabled {
            @extend .show;
          }

          @include devicesMax(tablet) {
            @include dims(20vw);
            margin-top: 5vw;
          }

          @include devicesMax(mobile) {
            @include dims(50vw);
            margin: 0;
            margin-top: 10vw;
          }
        }
      }
    }
  }

  .questionsW {
    @include dims(40vw, auto);
    border-top: 1px solid #628f9a20;
    margin: 0 3vw 0 4vw;

    @include devicesMax(tablet) {
      @include dims(100%, auto);
      padding: 5vw;
      margin: 0;

      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    @include devicesMax(mobile) {
      justify-content: center;
      padding: 5vw;
    }

    .imgW {
      @include dims(35vw, auto);
      border-radius: 0 0 900px 900px;
      margin-top: -67%;
      overflow: hidden;

      @include devicesMax(tablet) {
        @include dims(40vw, 50vw);
        border-radius: 900px 900px 0 0;
        margin: 0;
      }

      @include devicesMax(mobile) {
        display: none;
      }
    }

    .info {
      display: flex;
      align-items: flex-start;

      margin-top: 7vw;
      margin-bottom: 2vw;

      @include devicesMax(tablet) {
        align-items: flex-start;
        justify-content: flex-end;
        flex-direction: column;
        margin-bottom: 0;
      }

      @include devicesMax(mobile) {
        align-items: center;
      }

      .text {
        color: #3b3b3b;
        font-family: DisplayJD;
        width: 20vw;

        @include devicesMax(tablet) {
          margin-bottom: 10vw;
          width: 30vw;
        }

        @include devicesMax(mobile) {
          text-align: center;
          width: 100%;
        }

        .title {
          font-size: 1.35rem;
          font-weight: 500;
          line-height: 120%;
          margin-bottom: 1.25vw;

          @include devicesMax(mobile) {
            font-size: 1.25rem;
            margin-bottom: 5vw;
          }
        }

        .par {
          font-size: 1rem;
          font-weight: 400;
          line-height: 180%;

          .link {
            font-weight: 700;
            font-style: italic;
            text-decoration: underline;
          }
        }
      }

      .links {
        margin-left: 5vw;

        @include devicesMax(tablet) {
          margin-left: 0;
        }

        @include devicesMax(mobile) {
          display: flex;
          flex-direction: column;
          margin-top: 10vw;
          text-align: center;
        }

        .link {
          color: #3b3b3b;
          display: inline-block;
          font-family: DisplayJD;
          font-size: 1rem;
          font-weight: 400;
          line-height: 140%;
          margin-bottom: 0.5vw;

          @include devicesMax(mobile) {
            margin-bottom: 5vw;

            &:last-child {
              margin-right: 0;
            }
          }
        }

        .legal {
          display: flex;
          flex-direction: column;
          margin-bottom: 2vw;
        }

        .social {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  .copyright {
    @include dims(100%, 8vh);
    border-top: 1px solid #628f9a20;
    color: #3b3b3b;
    font-family: DisplayJD;
    margin-top: auto;
    padding: 1.5vw 5vw;
    line-height: 160%;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include devicesMax(tablet) {
      @include dims(100%, auto);
      padding: 1.5vw 5vw 4vw 5vw;
      justify-content: space-between;
    }

    @include devicesMax(mobile) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10vw 5vw;
    }

    .allRights {
      font-size: 15px;
      font-weight: 700;
      margin-right: 6vw;

      @include devicesMax(mobile) {
        margin-right: 0;
      }
    }

    .signature {
      font-size: 15px;
      font-weight: 400;
      margin-right: 1vw;

      .jdfx {
        text-decoration: underline;
      }
    }
  }
}
