@import "../scss/utils.scss";
@import "../scss/variables.scss";

.preloaderW {
  @include position(fixed, 0, 0, 0, auto);
  @include dims(100vw, 100vh);
  background: linear-gradient(-135deg, #e4edee 20%, #f3e7e5 70%);
  transition: 1.2s all ease-in-out;
  transition-delay: 0.5s;
  z-index: 999999999;

  display: flex;
  align-items: center;
  justify-content: center;
  @extend .show;

  &.hide {
    @extend .hide;
  }

  .progress {
    @include position(absolute, 50%, auto, auto, 50%);
    transform: translate(-50%, -50%);
    z-index: 100;

    font-family: DisplayJD;
    font-size: 1.25vw;
    font-weight: 400;
    margin-bottom: 0.75em;
    line-height: 100%;
    transition: 0.6s all ease;

    &.hide {
      @extend .hide;
    }

    @include devicesMax(tablet) {
      font-size: 4vw;
    }

    @include devicesMax(mobile) {
      font-size: 6vw;
    }
  }

  .shape {
    @include dims(40vw, 75vh);
    background: transparent
      linear-gradient(180deg, #ffffff 0%, #f5eff1c7 43%, #f1e7eb00 100%) 0% 0%
      no-repeat padding-box;
    border-top-left-radius: 49%;
    border-top-right-radius: 49%;
    z-index: 50;
    transition: 0.6s opacity ease;

    &.hide {
      @extend .hide;
    }

    @include devicesMax(tablet) {
      @include dims(80vw, 65vh);
    }

    @include devicesMax(mobile) {
      @include dims(100vw, 60vh);
    }
  }
}
