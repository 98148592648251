@import "./assets/scss/normalize.scss";
@import "./assets/scss/reset.scss";
@import "./assets/font/fonts.scss";

html {
  @media screen and (min-width: 1025px) {
    body {
      background: linear-gradient(-135deg, #e4edee, #f3e7e5);
      height: 100%;
      width: 100%;
      overflow: hidden;
    }

    [data-scroller] {
      height: 100vh;
    }

    .scrollbar-track {
      background: transparent !important;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
small,
label,
a,
span,
strong {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  overflow: hidden;
}

* {
  cursor: default;

  // outline: 1px solid red;
  // outline-offset: -1px;
}
