.magneticButton {
  border: none;
  padding: 1rem;
  position: relative;
  z-index: 1;
  touch-action: none;

  .inner {
    display: inline-block;
  }

  .hover {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
