@import "../scss/utils.scss";
@import "../scss/variables.scss";

.sidebar {
  @include position(fixed, 0, auto, 0, 0);
  @include dims($sidebar-width, 100vh);
  border-right: 1px solid #628f9a20;
  padding: 2.5vw 0;
  z-index: 10000;

  display: flex;
  flex-direction: column;
  align-items: center;

  @include devicesMax(tablet) {
    @include position(fixed, auto, auto, auto, auto);
    @include dims(100%, $sidebar-width-tablet);
    background: #e4edee;
    border-right: none;
    border-bottom: 1px solid #628f9a20;
    padding: 0 4vw;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  @include devicesMax(mobile) {
    @include dims(100%, calc($sidebar-width-mobile));
    padding: 5.5vw 5vw;
  }

  .logoW {
    @include dims(70%, auto);
    @extend .cursorPointer;
    display: block;

    @include devicesMax(tablet) {
      @include dims(15%, auto);
      margin-right: auto;
    }

    @include devicesMax(mobile) {
      @include dims(30%, auto);
    }
  }

  .langW {
    @include dims(4vw);

    display: none;
    align-items: center;
    justify-content: center;

    @include devicesMax(tablet) {
      display: flex;
    }
  }

  .xBurger {
    @include position(absolute, 50%, auto, auto, 50%);
    @extend .cursorPointer;
    transform: translate3d(-50%, -50%, 0);
    z-index: 9999999;

    display: flex;
    align-items: center;
    justify-content: center;

    @include devicesMax(tablet) {
      @include position(relative, auto, auto, auto, auto);
      margin-left: 2vw;
      transform: translate3d(0, 0, 0);
    }

    @include devicesMax(mobile) {
      margin-left: 5vw;
    }

    .lines {
      @include dims(2.5vw, 3vw);
      flex-shrink: 0;
      overflow: hidden;
      position: relative;

      @include devicesMax(tablet) {
        @include dims(4.5vw, 4vw);
      }

      @include devicesMax(mobile) {
        @include dims(8vw);
      }

      .line {
        @include dims(100%, 3px);
        border-radius: 4px;
        background: #3b3b3b;

        @include devicesMax(tablet) {
          @include dims(100%, 2px);
        }

        &.horizontal1 {
          @include position(absolute, 25%, 0, auto, 0);
          transition: 0.2s all ease;
          transition-delay: 0.4s;
        }

        &.horizontal2 {
          @include position(absolute, 50%, 0, auto, 0);
          transition: 0.4s all ease;
          transition-delay: 0.4s;
        }

        &.horizontal3 {
          @include position(absolute, 75%, 0, auto, 0);
          transition: 0.6s all ease;
          transition-delay: 0.4s;
        }
      }

      .cross1W {
        @include position(absolute, 50%, auto, auto, 50%);
        @include dims(100%, 3px);
        border-radius: 4px;
        transform: rotate(45deg) translate3d(-50%, -50%, 0);
        transform-origin: 0 50%;

        @include devicesMax(tablet) {
          @include dims(100%, 2px);
        }

        .cross1 {
          @include dims(100%);
          background: #3b3b3b;
          border-radius: 4px;
          transform: translate3d(130%, 0, 0);
          transition: 0.3s all ease-in-out;
        }
      }

      .cross2W {
        @include position(absolute, 50%, auto, auto, 50%);
        @include dims(100%, 3px);
        border-radius: 4px;
        transform: rotate(-45deg) translate3d(-50%, -50%, 0);
        transform-origin: 0 100%;

        @include devicesMax(tablet) {
          @include dims(100%, 2px);
        }

        .cross2 {
          @include dims(100%);
          background: #3b3b3b;
          border-radius: 4px;
          transform: translate3d(130%, 0, 0);
          transition: 0.3s all ease-in-out;
        }
      }
    }

    &.open {
      .lines {
        .line {
          &.horizontal1,
          &.horizontal2,
          &.horizontal3 {
            transform: translate3d(-103%, 0, 0);
            transition-delay: 0s;
          }
        }

        .cross1W {
          .cross1 {
            transform: translate3d(-3%, 0, 0);
            transition-delay: 1s;
          }
        }

        .cross2W {
          .cross2 {
            transform: translate3d(3%, 0, 0);
            transition-delay: 1s;
          }
        }
      }
    }
  }
}
