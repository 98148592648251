@import "../scss/utils.scss";

.blog {
  .doubleTitleW {
    position: relative;
    margin: 10vw 3vw 3vw 5vw;
    white-space: nowrap;

    @include devicesMax(mobile) {
      text-align: center;
    }

    .title {
      font-family: Kraton;
      font-weight: 400;
      line-height: 100%;

      &:nth-child(1) {
        color: #3b3b3b;
        font-size: 110px;

        @include devicesMax(tablet) {
          font-size: 10vw;
        }

        @include devicesMax(mobile) {
          font-size: 15vw;
        }
      }

      &:nth-child(2) {
        @include position(absolute, 0, auto, auto, 4%);
        color: #495660;
        font-size: 192px;
        opacity: 0.05;

        @include devicesMax(tablet) {
          font-size: 16vw;
        }

        @include devicesMax(mobile) {
          @include position(absolute, 0%, auto, auto, 50%);
          font-size: 21vw;
          transform: translateX(-50%);
        }
      }
    }
  }

  .blogsW {
    margin: 5vw 4vw;
    padding-bottom: 7vw;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5vw 0;

    @include devicesMax(tablet) {
      margin: 4vw 5vw;

      grid-template-columns: repeat(2, 1fr);
      gap: 10vw 0;
    }

    @include devicesMax(mobile) {
      gap: 15vw 0;
      grid-template-columns: repeat(1, 1fr);
      padding-bottom: 20vw;
      margin: 0;
    }
  }
}

.search {
  margin: 0 3vw;

  @include devicesMax(mobile) {
    margin: 0 5vw;
  }

  .searchbar {
    @include dims(35vw, auto);
    margin-left: auto;

    @include devicesMax(tablet) {
      @include dims(50vw, auto);
    }

    @include devicesMax(mobile) {
      @include dims(100%, auto);
    }

    .form {
      display: flex;
      align-items: center;
      justify-content: center;

      margin: 3vw 0 1vw 0;

      @include devicesMax(mobile) {
        margin-top: 10vw;
      }

      .inputW {
        $fontSize: 1.25rem;
        @include dims(100%, 3vw);
        border-bottom: 1px solid #3b3b3b;
        position: relative;

        @include devicesMax(tablet) {
          @include dims(100%, 5vw);
          margin: 3vw 0 2vw 0;
        }

        @include devicesMax(mobile) {
          @include dims(100%, 8vw);
          margin: 3vw 0 5vw 0;
        }

        .label {
          @include position(absolute, 0, 0, 0, 0);
          @extend .flexstartH;
          color: #3b3b3b;
          font-family: DisplayJD;
          font-size: $fontSize;
          font-weight: 500;
          line-height: 120%;

          &.hidden {
            transition: 0s;
            @extend .hide;
          }

          @include devicesMax(mobile) {
            font-size: 1rem;
          }
        }

        .input {
          @include position(absolute, 0, 0, 0, 0);
          @extend .flexstartH;
          color: #3b3b3b;
          font-family: DisplayJD;
          font-size: $fontSize;
          font-weight: 400;
          line-height: 120%;
          margin: 0;
          padding: 0;
          padding-right: 2vw;
          background: none;
          border: none;
          outline: none;

          @include devicesMax(mobile) {
            font-size: 1rem;
          }
        }

        .iconW {
          @include position(absolute, 50%, 0, auto, auto);
          transform: translateY(-50%);
          pointer-events: none;
        }
      }

      .cleanForm {
        @extend .cursorPointer;
        margin-left: 2.25rem;

        display: flex;
        align-items: center;
        justify-content: center;
        @extend .hide;
        transition: 0.4s all ease;

        @include devicesMax(mobile) {
          display: none;
        }

        &.enabled {
          @extend .show;
        }

        .text {
          color: #3b3b3b;
          font-family: DisplayJD;
          font-size: 1.25rem;
          font-weight: 500;
          line-height: 120%;
        }
      }
    }
  }
}
