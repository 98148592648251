@mixin dims($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin font-size($font-size, $line-height: normal, $letter-spacing: normal) {
  font-size: $font-size * 1px;
  // font-size: $font-size * 0.1rem;
  // example using rem values and 62.5% font-size so 1rem = 10px

  @if $line-height==normal {
    line-height: normal;
  } @else {
    line-height: $line-height / $font-size;
  }

  @if $letter-spacing==normal {
    letter-spacing: normal;
  } @else {
    letter-spacing: #{$letter-spacing / $font-size}em;
  }
}

@mixin position(
  $position,
  $top: auto,
  $right: auto,
  $bottom: auto,
  $left: auto
) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin devicesMax($breakpoint) {
  @if $breakpoint == desktop {
    @media only screen and (max-width: 1440px) {
      @content;
    }
  }

  @if $breakpoint == tablet {
    @media only screen and (max-width: 1024px) {
      @content;
    }
  }

  @if $breakpoint == mobile {
    @media only screen and (max-width: 640px) {
      @content;
    }
  }
}

@mixin devicesMin($breakpoint) {
  @if $breakpoint == desktop {
    @media only screen and (min-width: 1680px) {
      @content;
    }
  }

  @if $breakpoint == tablet {
    @media only screen and (min-width: 1024px) {
      @content;
    }
  }

  @if $breakpoint == mobile {
    @media only screen and (min-width: 640px) {
      @content;
    }
  }
}

.hide {
  visibility: hidden;
  opacity: 0;
}

.show {
  visibility: visible;
  opacity: 1;
}

.cursorPointer {
  cursor: pointer;

  * {
    cursor: pointer;
  }
}

.abscenter {
  @include position(absolute, 50%, auto, auto, 50%);
  transform: translate(-50%, -50%);
}

.flexcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexstartH {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flexendH {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flexstartV {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.flexendV {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
