@import "../scss/utils.scss";

.blogCard {
  @include dims(23vw, 42vw);
  display: flex;
  flex-direction: column;
  min-height: 45vw;
  margin-right: 5vw;

  @include devicesMax(tablet) {
    @include dims(30vw, auto);
    min-height: 72vw;
    margin-right: 5vw;
  }

  @include devicesMax(mobile) {
    @include dims(90vw, auto);
    margin: 0 5vw;
    min-height: 160vw;
  }

  .imgW {
    @include dims(23vw);
    margin-bottom: 2.25vw;

    @include devicesMax(tablet) {
      @include dims(30vw);
      margin-bottom: 3vw;
    }

    @include devicesMax(mobile) {
      @include dims(90vw);
      margin-bottom: 7vw;
    }
  }

  .title {
    color: #3b3b3b;
    font-family: DisplayJD;
    font-size: 1.25vw;
    font-weight: 700;
    line-height: 140%;
    margin-bottom: 0.5vw;

    @include devicesMax(tablet) {
      font-size: 2.5vw;
      margin-bottom: 1.5vw;
    }

    @include devicesMax(mobile) {
      font-size: 6vw;
      margin-bottom: 3vw;
    }
  }

  .text {
    color: #3b3b3b;
    font-family: DisplayJD;
    font-size: 1.2vw;
    font-weight: 400;
    line-height: 180%;
    margin-bottom: 2vw;

    @include devicesMax(tablet) {
      font-size: 2vw;
    }

    @include devicesMax(mobile) {
      font-size: 4vw;
      margin-bottom: 5vw;
    }
  }

  .readMore {
    color: #3b3b3b;
    font-family: DisplayJD;
    font-size: 1.2vw;
    font-weight: 500;
    margin-top: auto;
    text-decoration: underline;

    @include devicesMax(tablet) {
      font-size: 2.25vw;
    }

    @include devicesMax(mobile) {
      font-size: 5vw;
      margin-bottom: 1.5vw;
    }
  }
}
