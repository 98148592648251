@font-face {
  font-family: "DisplayJD";
  src: url("./fontfiles/DisplayJD/DisplayJD-Bold.woff2") format("woff2"),
    url("./fontfiles/DisplayJD/DisplayJD-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DisplayJD";
  src: url("./fontfiles/DisplayJD/DisplayJD-Medium.woff2") format("woff2"),
    url("./fontfiles/DisplayJD/DisplayJD-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DisplayJD";
  src: url("./fontfiles/DisplayJD/DisplayJD-Regular.woff2") format("woff2"),
    url("./fontfiles/DisplayJD/DisplayJD-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DisplayJD";
  src: url("./fontfiles/DisplayJD/DisplayJD-Light.woff2") format("woff2"),
    url("./fontfiles/DisplayJD/DisplayJD-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kraton";
  src: url("./fontfiles/Kraton/Kraton-Regular.woff2") format("woff2"),
    url("./fontfiles/Kraton/Kraton-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
