@import "./utils.scss";

.magnetCursor {
  @include position(fixed, 0, auto, auto, 0);
  @include dims(1rem);
  opacity: 0;
  pointer-events: none;
  transition: 0.1s opacity ease;
  z-index: 10000000000;

  &.visible {
    opacity: 1;
  }

  @include devicesMax(tablet) {
    display: none;
  }

  .cursor {
    .inner {
      @include position(absolute, 50%, auto, auto, 50%);
      transform: translate(-50%, -50%);
      @extend .hide;

      &.texted {
        @include position(absolute, 50%, auto, auto, 50%);
        transform: translate(-50%, -80%) rotate(-10deg);
        @include dims(10rem, 3rem);
        background: rgb(255, 255, 255, 0.5);
        backdrop-filter: blur(30px);
        border-radius: 8px;
        -webkit-backdrop-filter: blur(30px);
        transition: 0.4s opacity ease;
        z-index: -1;
        @extend .show;

        .text {
          @include position(absolute, 50%, auto, auto, 50%);
          transform: translate(-50%, -50%);
          color: #3b3b3b;
          font-family: DisplayJD;
          font-size: 1.5rem;
          font-style: italic;
          font-weight: 500;
          transition: 0.4s opacity ease-in-out;
          white-space: nowrap;
          @extend .hide;

          &.active {
            @extend .show;
          }
        }
      }

      &.default {
        @include dims(1.5rem);
        background: #3b3b3b;
        border-radius: 50%;
        opacity: 0.5;
        position: relative;
        transform-origin: center center;
        transform: translate(-50%, -50%);
        transition: 0.2s opacity ease;
        will-change: transform opacity;
        @extend .show;
      }

      &.hidden {
        transition: 0.3s all ease;
        transform: scale(4);
        opacity: 0;
        @extend .hide;
      }
    }
  }

  // &.circled {
  //   .cursor {
  //     background: transparent;
  //     border: 1px solid #00509d;
  //     display: block;
  //     transform: scale(3);
  //   }
  // }

  // &.scaled {
  //   .cursor {
  //     transform: scale(1.5);
  //   }
  // }
}
