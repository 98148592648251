@import "../scss/utils.scss";

.slider {
  position: relative;

  display: flex;
  justify-content: space-between;

  @include devicesMax(tablet) {
    flex-direction: column;
    align-items: center;
  }

  .textW {
    color: #3b3b3b;

    @include devicesMax(tablet) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 5vw;
    }

    @include devicesMax(mobile) {
      margin-bottom: 0;
    }

    .titleW {
      margin-bottom: 10vw;

      @include devicesMax(tablet) {
        margin-bottom: 5vw;
      }

      .mask {
        @include dims(50vw, 9vw);
        position: relative;
        // border: 1px solid red;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: -80px;

        @include devicesMax(tablet) {
          @include dims(80vw, 14vw);
          margin-top: -2vw;
        }

        @include devicesMax(mobile) {
          @include dims(95vw, 15vw);
        }

        .circlee {
          @include dims(0vw);
          border-radius: 50%;
          // border: 1px solid blue;
          margin-left: -120px;
          transition: 1.2s all ease;
          transform-origin: center;
          will-change: transform;

          @include devicesMax(mobile) {
            margin-top: 25vw;
            margin-left: -110px;
          }

          .text {
            @include dims(0vw);
            @include position(absolute, 50%, auto, auto, 50%);
            transform: translate(-50%, -50%);
            text-align: center;

            color: #495660;
            font-family: Kraton;
            font-size: 7.5vw;
            font-weight: 400;
            line-height: 80%;
            will-change: transform;

            @include devicesMax(tablet) {
              font-size: 12vw;
            }

            @include devicesMax(mobile) {
              font-size: 12vw;
            }

            .inner {
              transform: translateX(199px) rotate(18deg);
            }
          }
        }
      }

      .title {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        position: relative;

        &.titleTop {
          color: #495660;
          font-family: DisplayJD;
          font-size: 6vw;
          font-weight: 300;
          line-height: 100%;
          letter-spacing: -0.05em;

          @include devicesMax(tablet) {
            font-size: 9vw;
          }

          @include devicesMax(mobile) {
            font-size: 10vw;
            margin-left: 4vw;
          }

          .iconW {
            @include dims(9vw);
            display: inline-block;
            margin-left: 4vw;
            transform: translateY(-15%);

            @include devicesMax(tablet) {
              @include dims(15vw);
              margin-left: 6vw;
            }
          }
        }

        // &.titleBottom {
        //   color: #495660;
        //   font-family: Kraton;
        //   font-size: 130px;
        //   font-weight: 400;
        //   line-height: 100%;
        //   transform: translateY(-25%);

        //   @include devicesMax(mobile) {
        //     font-size: 12vw;
        //   }
        // }
      }
    }

    .descW {
      width: 20vw;

      @include devicesMax(tablet) {
        width: 60vw;
      }

      @include devicesMax(mobile) {
        width: 80vw;
      }

      .desc {
        font-family: DisplayJD;
        font-size: 20px;
        font-weight: 400;
        line-height: 200%;
        font-style: italic;

        @include devicesMax(tablet) {
          font-size: 2.5vw;
          text-align: center;
        }

        @include devicesMax(mobile) {
          font-size: 4vw;
        }
      }
    }
  }

  .btnAndImgW {
    position: relative;
    margin-top: auto;

    .imgW {
      @include dims(30vw, 35vw);
      border-top-left-radius: 250px;
      border-top-right-radius: 250px;
      margin-top: 3vw;
      overflow: hidden;
      position: relative;
      z-index: 100;

      @include devicesMax(tablet) {
        @include dims(55vw, 65vw);
        border-top-left-radius: 350px;
        border-top-right-radius: 350px;
      }

      @include devicesMax(mobile) {
        @include dims(80vw, 90vw);
        margin-top: 10vw;
      }

      .tWr {
        @include position(absolute, 0, 0, 0, 0);
        @include dims(100%);
        overflow: hidden;
        transform-origin: center;
        will-change: transform;

        .aWr {
          @include dims(100%);
          transform-origin: center;
          will-change: transform;
        }
      }
    }

    .btnW {
      @include position(absolute, auto, auto, -20%, -20%);
      @include dims(12.5vw);
      pointer-events: none;
      z-index: 200;

      @include devicesMax(tablet) {
        @include position(absolute, auto, auto, -10%, -20%);
        @include dims(25vw);
      }

      @include devicesMax(mobile) {
        display: none;
      }
    }
  }
}
