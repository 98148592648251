@import "../scss/utils.scss";
@import "../scss/variables.scss";

.footer {
  position: relative;

  &::before {
    content: "";
    @include position(absolute, 0, 0, auto, 0);
    @include dims(100%, 1px);
    background: #628f9a20;
  }

  .top {
    display: flex;
    align-items: flex-start;

    @include devicesMax(mobile) {
      flex-direction: column;
      align-items: center;
    }

    .contact {
      flex: 1;
      margin: 5vw 4vw 3vw 4vw;

      @include devicesMax(tablet) {
        margin: 5vw 2vw 3vw 4vw;
      }

      @include devicesMax(mobile) {
        margin: 15vw 0;
      }

      .title {
        color: #3b3b3b;
        font-family: DisplayJD;
        font-size: 3vw;
        font-weight: 500;
        line-height: 120%;

        @include devicesMax(tablet) {
          font-size: 3.5vw;
        }

        @include devicesMax(mobile) {
          font-size: 8vw;
          margin: 0 auto;
          text-align: center;
        }
      }

      .btnW {
        display: block;
        margin-left: 20vw;
        transform: translateY(-25%);

        @include devicesMax(tablet) {
          margin-left: 25vw;
          transform: translateY(-10%);
        }

        @include devicesMax(mobile) {
          transform: translateY(0);
          margin: 0;
        }

        .magneticW {
          @include dims(12.5vw);

          @include devicesMax(tablet) {
            @include dims(20vw);
          }

          @include devicesMax(mobile) {
            @include dims(45vw);
            margin: 10vw auto 0 auto;
          }
        }
      }
    }

    .subscribe {
      @include dims(35vw, auto);
      margin: 5vw 4vw 3vw 4vw;
      position: relative;

      @include devicesMax(tablet) {
        @include dims(37vw, auto);
        flex-shrink: 0;
      }

      @include devicesMax(mobile) {
        @include dims(100vw, auto);
        margin: 0;
        padding: 5vw 5vw 3vw 5vw;
      }

      .title {
        color: #3b3b3b;
        font-family: DisplayJD;
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 120%;
        margin-bottom: 1vw;

        @include devicesMax(tablet) {
          font-size: 1rem;
        }

        @include devicesMax(mobile) {
          font-size: 14px;
          margin-bottom: 3vw;
          text-align: center;
        }
      }

      .par {
        color: #3b3b3b;
        font-family: DisplayJD;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 160%;

        @include devicesMax(tablet) {
          font-size: 0.85rem;
        }

        @include devicesMax(mobile) {
          font-size: 14px;
          line-height: 180%;
          text-align: center;
        }
      }

      .form {
        @include devicesMax(mobile) {
          margin-top: 10vw;
        }

        .inputW {
          $fontSize: 1.5rem;
          $fontSizeTablet: 0.85rem;
          $fontSizeMobile: 1rem;

          @include dims(100%, 3vw);
          border-bottom: 1px solid #3b3b3b;
          margin: 3vw 0 1vw 0;
          position: relative;

          @include devicesMax(tablet) {
            @include dims(100%, 5vw);
            margin: 3vw 0 2vw 0;
          }

          @include devicesMax(mobile) {
            @include dims(100%, 8vw);
            margin: 3vw 0 5vw 0;
          }

          .label {
            @include position(absolute, 0, 0, 0, 0);
            @extend .flexstartH;
            color: #3b3b3b;
            font-family: DisplayJD;
            font-size: $fontSize;
            font-weight: 500;
            line-height: 120%;
            opacity: 0.5;
            transition: 0.4s all ease;

            &.hidden {
              transition: 0s;
              @extend .hide;
            }

            @include devicesMax(tablet) {
              font-size: $fontSizeTablet;
            }

            @include devicesMax(mobile) {
              font-size: $fontSizeMobile;
            }
          }

          .input {
            @include position(absolute, 0, 0, 0, 0);
            @extend .flexstartH;
            color: #3b3b3b;
            font-family: DisplayJD;
            font-size: $fontSize;
            font-weight: 400;
            line-height: 120%;
            margin: 0;
            padding: 0;
            background: none;
            border: none;
            outline: none;

            @include devicesMax(tablet) {
              font-size: $fontSizeTablet;
            }

            @include devicesMax(mobile) {
              font-size: 1rem;
            }
          }

          .iconW {
            @include position(absolute, 50%, 0, auto, auto);
            transform: translateY(-50%);
            opacity: 0.2;
            pointer-events: none;
            transition: 0.3s all ease;
            @extend .cursorPointer;

            &.enabled {
              @extend .show;
              pointer-events: auto;
            }
          }
        }

        .confirmation {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;

          @extend .cursorPointer;

          .checkbox {
            $boxDims: 20px;
            $boxDimsMobile: 16px;

            @include dims($boxDims);
            background: #fff;
            border: 1px solid #3b3b3b;
            border-radius: 4px;
            flex-shrink: 0;
            position: relative;

            @include devicesMax(mobile) {
              @include dims($boxDimsMobile);
            }

            .inner {
              @extend .abscenter;
              @extend .hide;
              @include dims(calc($boxDims - 6px));
              background: #3b3b3b;
              border-radius: 2px;
              transition: 0.3s all ease;

              @include devicesMax(mobile) {
                @include dims(calc($boxDimsMobile - 6px));
              }

              &.enabled {
                @extend .show;
              }
            }
          }

          .text {
            margin-left: 0.5rem;

            .small,
            .link {
              color: #3b3b3b;
              display: inline;
              font-family: DisplayJD;
              font-size: 0.9vw;
              font-weight: 400;
              line-height: 180%;

              @include devicesMax(tablet) {
                font-size: 8px;
                line-height: 160%;
              }

              @include devicesMax(mobile) {
                font-size: 10px;
                line-height: 180%;
              }
            }

            .link {
              display: inline-block;
              text-decoration: underline;
            }
          }

          // .text {
          //   color: #3b3b3b;
          //   display: inline-block;
          //   font-family: DisplayJD;
          //   font-size: 0.9rem;
          //   font-weight: 400;
          //   line-height: 180%;
          //   margin-left: 0.5rem;

          //   @include devicesMax(tablet) {
          //     font-size: 8px;
          //     line-height: 160%;
          //   }

          //   @include devicesMax(mobile) {
          //     font-size: 10px;
          //     line-height: 180%;
          //   }

          //   .link {
          //     text-decoration: underline;
          //   }
          // }
        }
      }

      .error {
        @include position(absolute, 95%, auto, auto, auto);
        color: #3b3b3b;
        font-family: DisplayJD;
        font-size: 1rem;
        font-weight: 400;
        line-height: 160%;
        margin: 2vw 0;

        @include devicesMax(tablet) {
          font-size: 14px;
        }

        @include devicesMax(mobile) {
          font-size: 11px;
          width: 80%;
        }
      }
    }
  }

  .bottom {
    padding: 2vw 0 4vw 0;

    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    @include devicesMax(tablet) {
      padding: 2vw 0 5vw 0;
    }

    @include devicesMax(mobile) {
      flex-direction: column;
    }

    .links {
      @extend .flexendV;

      @include devicesMax(mobile) {
        @include dims(100%, auto);
        padding: 10vw 0;

        align-items: center;
        flex-direction: column;
        justify-content: space-between;
      }

      .link {
        color: #3b3b3b;
        font-family: DisplayJD;
        font-size: 15px;
        font-weight: 400;
        line-height: 160%;

        @include devicesMax(mobile) {
          margin-bottom: 3vw;
          text-align: center;
        }
      }

      .lol {
        padding: 0 4vw;

        display: flex;
        flex-direction: column;
      }

      .social {
        padding: 0 4vw;

        display: flex;
        flex-direction: column;

        @include devicesMax(mobile) {
          margin-top: 5vw;
          padding: 0;
        }
      }
    }

    .copyright {
      @include dims(35vw, auto);
      color: #3b3b3b;
      font-family: DisplayJD;
      margin: 0 4vw;
      line-height: 160%;

      @include devicesMax(tablet) {
        @include dims(28vw, auto);
      }

      @include devicesMax(mobile) {
        @include dims(100%, auto);
        margin: 0;
        padding: 0 4vw;
        text-align: center;
      }

      .allRights {
        font-size: 14px;
        font-weight: 700;

        @include devicesMax(tablet) {
          font-size: 1.25vw;
        }

        @include devicesMax(mobile) {
          font-size: 15px;
          margin-bottom: 3vw;
        }
      }

      .signature {
        font-size: 15px;
        font-weight: 400;

        @include devicesMax(tablet) {
          font-size: 1.5vw;
        }

        @include devicesMax(mobile) {
          font-size: 15px;
        }

        .jdfx {
          text-decoration: underline;
        }
      }
    }
  }
}
