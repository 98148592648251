@import "../scss/utils.scss";

.projectsSlider {
  position: relative;

  @include devicesMax(mobile) {
    @include dims(auto, 140vw);
  }

  .navigation {
    @include position(absolute, 50%, auto, auto, 0);
    transform: translate(150%, -50%);
    z-index: 100;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include devicesMax(mobile) {
      @include position(absolute, 25%, auto, auto, 50%);
      @include dims(100%, auto);
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 0 2vw;
      transform: translate(-50%, 0);
    }

    .btn {
      @include dims(3vw, 1.75vw);
      @extend .cursorPointer;
      background: #f2f2f2;
      border-radius: 46px;
      margin-bottom: 0.5vw;

      @include devicesMax(tablet) {
        @include dims(5vw, 3vw);
        margin-bottom: 1vw;
      }

      @include devicesMax(mobile) {
        @include dims(10vw, 7vw);
        margin-bottom: 1vw;
      }

      .iconW {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .sliderW {
    margin-left: auto;
    width: 90%;

    @include devicesMax(tablet) {
      width: 85%;
    }

    @include devicesMax(mobile) {
      width: 100%;
    }
  }

  .btnW {
    @include position(absolute, 85%, 10vw, auto, auto);

    @include devicesMax(mobile) {
      @include position(absolute, auto, auto, 0, 50%);
      transform: translateX(-50%);
    }

    .magneticW {
      @include dims(12.5vw);

      @include devicesMax(tablet) {
        @include dims(20vw);
      }

      @include devicesMax(mobile) {
        @include dims(45vw);
      }
    }
  }
}
